<style lang="scss">
.v-autocomplete {
  position: relative;

  .v-autocomplete-input-group {
    .v-autocomplete-input {
      font-size: 1.5em;
      padding: 10px 15px;
      box-shadow: none;
      border: 1px solid #157977;
      width: calc(100% - 32px);
      outline: none;
      background-color: #eee;
    }
    &.v-autocomplete-selected {
      .v-autocomplete-input {
        color: #008000;
        background-color: #f2fff2;
      }
    }
  }
  .v-autocomplete-list {
    z-index: 10;
    width: 100%;
    text-align: left;
    border: none;
    border-top: none;
    max-height: 400px;
    overflow-y: auto;
    border-bottom: 1px solid #7367f0;
    position: absolute;
    .v-autocomplete-list-item {
      cursor: pointer;
      background: white;
      padding: 10px;
      border-bottom: 1px solid #7367f0;
      border-left: 1px solid #7367f0;
      border-right: 1px solid #7367f0;
      &:last-child {
        border-bottom: none;
      }
      &:hover {
        background: #ececec;
      }
      abbr {
        opacity: 0.8;
        font-size: 0.8em;
        display: block;
        font-family: sans-serif;
      }
    }
  }
}

.dark-layout {
  .v-autocomplete-list-item {
    background-color: #283046 !important;
    &:hover {
      background-color: #283050 !important;
    }
  }
}
</style>

<template>
  <!-- Docs: https://www.npmjs.com/package/v-autocomplete -->
  <v-autocomplete
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
export default {
  name: 'CAutoComplete'
}
</script>
