<script>
import CalendarEventsService from "@/services/CalendarEventsService";
import GroupService from "@/services/GroupService";
import BCardActions from "@/@core/components/b-card-actions/BCardActions.vue";
import CalendarEventModal from "@/views/calendar/sections/CalendarEventModal.vue";
import helperService from "@/services/HelperService";
import vSelect from "vue-select";
import moment from "moment";

export default {
  components: {vSelect, CalendarEventModal, BCardActions},
  label: "CalendarEvents",
  data() {
    return {
      Deselect: {
        render: createElement => createElement('span', 'x'),
      },
      loadingGroups: true,
      calendarEvents: [],
      groups: [],
      filters: {
        page: 0,
        page_size: 25,
        level: '',
        start_date: moment(new Date()).startOf('day').format('YYYY-MM-DD'),
        end_date: null,
        group_uids: [],
        search: '',
      },
      currentPage: 1,
      total_count: 0,
      headers: [
        {
          key: 'model',
          label: 'Level',
        },
        {
          key: 'group',
          label: 'Group Name',
        },
        {
          key: 'title',
          label: 'Title',
        },
        {
          key: 'content',
          label: 'Content',
        },
        {
          key: 'start_date',
          label: 'State date time',
          sortable: true,
        },
        {
          key: 'end_date',
          label: 'End date time',
          sortable: true,
        },
      ],
      modalId: 'modal-create-edit-calendar-event',
      selectedEvent: null,
    }
  },
  computed: {
    modalTitle() {
      return this.selectedEvent ? 'Update Calendar Event' : 'Create Calendar Event';
    }
  },
  async mounted() {
    await Promise.all([
      this.getEvents(),
      this.getGroups(),
    ]);
  },
  methods: {
    refresh() {
      this.closeModals();
      this.currentPage = 1;
      this.getMoreEvents(this.currentPage);
    },
    async getEvents() {
      this.$refs.refreshCard.showLoading = true;
      try {
        const response = await CalendarEventsService.listEvents(this.filters);
        this.calendarEvents = response.data.data;
        this.filters.page = response.data.meta.page;
        this.filters.page_size = response.data.meta.page_size;
        this.total_count = response.data.meta.total_count;
      } catch (e) {
        let msg = 'Error getting events';

        const res = e.response;
        if (res && res.data.error) {
          msg = res.data.error
        }

        helperService.showNotfyErr(this.$toast, e, msg);
      } finally {
        setTimeout(() => {
          this.$refs.refreshCard.showLoading = false
        }, 100)
      }
    },
    getMoreEvents(page) {
      this.filters.page = page - 1;
      this.getEvents();
    },
    async getGroups() {
      try {
        const response = await GroupService.getGroups();
        this.groups = response.data;
      } catch (e) {
        let msg = 'Error getting groups';

        const res = e.response;
        if (res && res.data.error) {
          msg = res.data.error
        }

        helperService.showNotfyErr(this.$toast, e, msg);
      }
    },
    clearFilters() {
      this.filters = {
        page: 0,
        page_size: 25,
        level: '',
        start_date: null,
        end_date: null,
        group_uids: [],
        search: '',
      }
      this.getEvents();
    },
    openCreateModal() {
      if (!this.$can('Create', 'CalendarEvents')) {
        return;
      }
      this.selectedEvent = null;
      this.$bvModal.show(this.modalId);
    },
    openEditModal(item) {
      if (!this.$can('Update', 'CalendarEvents')) {
        return;
      }
      this.selectedEvent = item;
      this.$bvModal.show(this.modalId);
    },
    closeModals() {
      this.$bvModal.hide(this.modalId);
    },
    getGroupName(event) {
      if (!this.groups || !this.groups.length) {
        return null;
      }
      if (event.model === 'Enterprise') {
        return 'All groups'
      }
      const group = this.groups.find(g => g.uid === event.model_uid);
      return group.name;
    },
    updateGroupUids(value) {
      this.filters.group_uids = value || [];
      this.refresh();
    },
  }
}
</script>

<template>
  <div>
    <b-card title="Calendar Events">
      <b-btn
        v-if="$can('Create', 'CalendarEvents')"
        variant="primary"
        class="float-right"
        @click="openCreateModal"
      >
        Create Calendar Event
      </b-btn>
      <b-card-text>
        This page shows all Enterprise and Group level calendar events
      </b-card-text>
    </b-card>

    <b-card-actions no-actions class="mb-0" no-body ref="refreshCard">
      <b-col cols="12" class="m-0 p-1">
        <b-row class="mb-1">
          <b-col cols="4">
            <label for="eventLevel">Event level</label>
            <b-form-select v-model="filters.level" id="eventLevel" text="Event level" @change="refresh()">
              <b-form-select-option value="">All</b-form-select-option>
              <b-form-select-option value="Enterprise">Enterprise</b-form-select-option>
              <b-form-select-option value="Group">Group</b-form-select-option>
            </b-form-select>
          </b-col>

          <b-col cols="4">
            <label for="selectedGroupsDropdown">Groups</label>
            <div class="pl-0" v-if="$can('Read', 'Group')">
              <v-select
                v-model="filters.group_uids"
                :components="{Deselect}"
                :options="groups"
                :reduce="(option) => option.uid"
                dir="ltr"
                label="name"
                multiple
                placeholder="Select groups"
                @input="updateGroupUids"
              />
            </div>
          </b-col>

          <b-col cols="4">
            <label for="clearFilters">
              &nbsp;
            </label>
            <b-btn id="clearFilters" class="w-100" @click="clearFilters()">
              Clear Filters
            </b-btn>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4">
            <label>Search</label>
            <b-input-group>
              <b-form-input
                v-model="filters.search"
                class="d-inline-block"
                placeholder="Search..."
                @keydown.enter="refresh()"
              />
              <b-input-group-append>
                <b-button @click="refresh()">
                  Search
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
          <b-col cols="4">
            <label for="startDate">From</label>
            <b-datepicker v-model="filters.start_date" id="startDate" @input="refresh()" :reset-button="true"/>
          </b-col>
          <b-col cols="4">
            <label for="endDate">Until</label>
            <b-datepicker v-model="filters.end_date" id="endDate" @input="refresh()" :reset-button="true"/>
          </b-col>
        </b-row>
      </b-col>
      <section>
        <b-table
          hover
          class="data-table mb-0"
          :items="calendarEvents"
          :fields="headers"
          :current-page="currentPage"
          :per-page="0"
          :responsive="true"
          show-empty
          @row-clicked="openEditModal"
        >
          <template #cell(level)="data">
            {{ data.item.model }}
          </template>
          <template #cell(group)="data">
            {{ getGroupName(data.item) }}
          </template>
          <template #cell(title)="data">
            <div class="line-clamp">
              {{ data.item.title }}
            </div>
          </template>
          <template #cell(content)="data">
            <div class="line-clamp">
              {{ data.item.content }}
            </div>
          </template>
          <template #cell(start_date)="data">
            {{ data.item.start_date | formatDateTime }}
          </template>
          <template #cell(end_date)="data">
            {{ data.item.end_date | formatDateTime }}
          </template>
        </b-table>
        <b-row>
          <b-col md="6" class="my-1 mx-2" v-if="total_count > filters.page_size">
            <b-pagination
              v-model="currentPage"
              class="my-0"
              :total-rows="total_count"
              :per-page="filters.page_size"
              @change="getMoreEvents"
            />
          </b-col>
        </b-row>
      </section>
    </b-card-actions>

    <b-modal :id="modalId" :title="modalTitle" hide-footer no-close-on-backdrop>
      <CalendarEventModal
        :event="selectedEvent"
        :groups="groups"
        @close="closeModals"
        @refresh="refresh"
      />
    </b-modal>
  </div>
</template>

<style>
.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
